import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const supabaseUrl = process.env.REACT_APP_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_PUBLIC_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseAnonKey);
const ORIGINAL_APP_URL = "https://share.ultimateai.org/auth/login";

// Function to get or create a long-lived token
async function getOrCreateToken(userId) {
  let { data: token, error } = await supabase
    .from('tokens')
    .select('long_lived_token')
    .eq('user_id', userId)
    .single();

  if (error && error.code !== 'PGRST116') {
    throw error;
  }

  if (!token) {
    const newToken = uuidv4();
    const { data, error: insertError } = await supabase
      .from('tokens')
      .insert({ user_id: userId, long_lived_token: newToken })
      .select('long_lived_token')
      .single();

    if (insertError) throw insertError;
    token = data;
  }

  return token.long_lived_token;
}

// Function to handle posting the token and node ID
function postToOriginalApp(token, carid) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = ORIGINAL_APP_URL;

  const addHiddenField = (name, value) => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = name;
    hiddenField.value = value;
    form.appendChild(hiddenField);
  };

  addHiddenField('usertoken', token);
  addHiddenField('carid', carid);

  document.body.appendChild(form);
  form.submit();
}

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      const { user, error } = await supabase.auth.signIn({
        email,
        password,
      });

      if (error) throw error;

      const longLivedToken = await getOrCreateToken(user.id);

      // Save token as cookie with 7 days expiration
      Cookies.set('user_token', longLivedToken, { expires: 48, path: '/' });

      const finalCarid = Math.random() < 0.5 ? 'n3' : 'n4';

      // Post to the original app URL
      postToOriginalApp(longLivedToken, finalCarid);

    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }
    try {
      const { error } = await supabase.auth.api.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.logoContainer}>
        <img src="https://auth.openai.com/assets/openai-logo-DmWoKcI3.svg" alt="Logo" style={styles.logo} />
      </div>
      <h2 style={styles.title}>Welcome back</h2>
      <form onSubmit={handleLogin} style={styles.form}>
        <label style={styles.label}>Email address*</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={styles.input}
        />
        <label style={styles.label}>Password*</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={styles.input}
        />
        <button type="button" onClick={handleForgotPassword} style={styles.forgotPassword}>
          Forgot password?
        </button>
        <div style={styles.buttonPadding}></div>
        <button type="submit" style={styles.button}>Continue</button>
        {message && <p style={styles.message}>{message}</p>}
      </form>
      <p style={styles.disclaimer}>
        Please note: This application will remain logged in for a number of days.  If you are using a shared or public computer, 
        use private or incognito mode.
      </p>
    </div>
  );
}

function PasswordReset() {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.api.updateUser(
        supabase.auth.session()?.access_token,
        { password: newPassword }
      );
      if (error) throw error;
      setMessage('Password has been reset successfully');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Reset Password</h2>
      <form onSubmit={handleResetPassword} style={styles.form}>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Reset Password</button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

function AcceptInvite({ token }) {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!token) {
      setMessage('Invalid invitation link');
    } else {
      setMessage('Please set your password to accept the invitation.');
    }
  }, [token]);

  const handleAcceptInvite = async (e) => {
    e.preventDefault();
    if (!token) {
      setMessage('Invalid invitation link');
      return;
    }

    try {
      const { error } = await supabase.auth.api.updateUser(token, {
        password: password,
      });

      if (error) throw error;

      setMessage('Invitation accepted successfully. You can now log in.');
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    } catch (error) {
      console.error('Error:', error.message);
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Accept Invitation</h2>
      <form onSubmit={handleAcceptInvite} style={styles.form}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Set your password"
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>Accept Invitation</button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
}

function App() {
  const location = useLocation();
  const [inviteToken, setInviteToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const type = searchParams.get('type');

    if (token && type === 'invite') {
      setInviteToken(token);
    }
  }, [location]);

  useEffect(() => {
    const checkSession = async () => {
      const token = Cookies.get('user_token');
      if (token) {
        // Ensure the node ID is consistent by checking for an existing cookie or generating a new one
        let nodeId = Cookies.get('node_id');
        if (!nodeId) {
          nodeId = Math.random() < 0.5 ? 'n3' : 'n4';
          Cookies.set('node_id', nodeId, { expires: 7, path: '/' });
        }

        // Post to the original app URL with the token and node ID
        postToOriginalApp(token, nodeId);
        return;
      }
      setIsLoading(false); // Finished checking session
    };

    checkSession();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (inviteToken) {
    return <AcceptInvite token={inviteToken} />;
  }

  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#ffffff',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: '50px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
  label: {
    marginBottom: '5px',
    fontSize: '14px',
    color: '#333333',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '16px',
    borderColor: '#00a884',
    borderRadius: '4px',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#00a884',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  message: {
    marginTop: '10px',
    color: 'red',
  },
  forgotPassword: {
    color: '#00a884',
    background: 'none',
    border: 'none',
    padding: '0',
    font: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom: '10px',
  },
  buttonPadding: {
    height: '10px',
  },
  disclaimer: {
    marginTop: '20px',
    fontSize: '12px',
    color: '#666666',
    textAlign: 'center',
  },
};

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}